import { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import NewNav from "../navbar/NewNav";
import Address from "../address/Address";
import careers from "../../assets/careers.png";
import axios from "axios";
import { GET_CAREER_JOBS } from "../Api";
import { formatDistanceToNow } from 'date-fns';

import { Button } from "@material-tailwind/react";

const Careers = () => {

    const jobsSectionRef = useRef(null);

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        // Fetch jobs from the backend
        const fetchJobs = async () => {
            try {
                const response = await axios.get(GET_CAREER_JOBS, {
                    params: {
                        userSide: 'user',
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setJobs(response.data.jobs);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };

        fetchJobs();
    }, []);

    const scrollToJobsSection = () => {
        jobsSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            <NewNav />
            <div className="Careers">
                {/* Header Section */}
                <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between mb-20 mt-10 px-5 md:px-10 lg:px-20 gap-10">
                    {/* Left Side - Text */}
                    <div className="lg:w-[900px] text-start mt-20">
                        <h1 className="text-4xl md:text-5xl font-extrabold">We’re more than just a workplace.</h1>
                        <h1 className="text-4xl md:text-5xl font-extrabold mt-5">We’re a family.</h1>
                        <p className="text-lg md:text-xl mt-10 text-gray-600 max-w-3xl leading-relaxed">
                            We know that finding a meaningful and rewarding career can be a long journey. Our goal is to make that process easy for you and to create a work environment that's enriching—one that you'll look forward to every day.
                        </p>
                        <Button
                            color="green"
                            variant="gradient"
                            size="sm"
                            className="mt-10 px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 "
                            onClick={scrollToJobsSection}
                        >
                            <span className="py-2 text-base">
                                Explore Job Opportunities
                            </span>
                        </Button>
                    </div>

                    {/* Right Side - Image */}
                    <div className=" flex justify-end mt-8 lg:mt-0">
                        <img
                            src={careers}
                            alt="Teamwork illustration"
                            className="w-[500px]"
                        />
                    </div>
                </div>

                <div
                    ref={jobsSectionRef}
                    className="flex flex-col items-center text-center px-5 md:px-10 lg:px-20 my-10">
                    <h1 className="text-4xl md:text-5xl font-normal mt-10 leading-tight">
                        Some opportunities
                    </h1>
                    <h1 className="text-4xl md:text-5xl font-normal mt-2 leading-tight">
                        for you to explore
                    </h1>
                </div>

                {/* Cards Section */}
                <div className="flex flex-wrap gap-6 justify-center mt-20 mb-10 px-5 md:px-10 lg:px-20">
    {jobs.length > 0 ? (
        jobs.map((job) => {
            const jobTime = new Date(job.createdAt._seconds * 1000);

            return (
                <Link
                    key={job.id}
                    to={`/careers/jobdetails?jobid=${job.id}`}
                    className="flex flex-col items-start text-left bg-white border border-gray-300 rounded-lg p-6 shadow-md w-full sm:w-1/2 lg:w-1/4 cursor-pointer transition-transform transform hover:scale-105 hover:ring-4 hover:ring-green-400 hover:shadow-lg"
                >
                    {/* Job Title */}
                    <h3 className="text-xl font-bold mb-3 transition-transform duration-300 hover:scale-105">
                        {job.jobTitle}
                    </h3>
                    {/* Job Description (with line clamp for truncation) */}
                    <p className="text-sm text-gray-600 mb-4 line-clamp-3">
                        {job.jobDescription}
                    </p>
                    {/* Job Time - Align to the right */}
                    <div className="flex justify-between items-center w-full">
                        <p className="text-sm text-gray-600 font-medium ml-auto">
                            {formatDistanceToNow(jobTime, { addSuffix: true })}
                        </p>
                    </div>
                </Link>
            );
        })
    ) : (
        // Loading State
        <p className="text-gray-500 text-center">Currently, there are no jobs available. Please check back later or explore other opportunities.</p>
    )}
</div>

            </div>
            <Address />
        </div>
    );
};

export default Careers;
