import './App.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Home from './components/homePage/Home'
import Products from './components/productsPage/Products';
import Contact from './components/contactPage/Contact';
import Careers from './components/careersPage/Careers';
import JobDetail from './components/careersPage/JobDetails';
import SignIn from './components/signIn/SignIn';
import AdminPage from './components/adminPage/AdminPage';
import Scroll from './components/Scroll';
import SocialMediaButtons from './components/SocialMediaButtons';
import ProductDetail from './components/productsPage/ProductDetail';
import AddClients from './components/adminPage/AddClientCompononents/AddClients';
import AddCategories from './components/adminPage/AddCategoriesComponents/AddCategories';
import AddProducts from './components/adminPage/AddProductComponents/AddProducts';
import AddManuals from './components/adminPage/AddManuals/AddManuals';
import AddCareers from './components/adminPage/AddCareers/addCareers';
import AddTestimonials from './components/adminPage/AddTestimonials/AddTestimonials';
import NotFound from './components/NotFound';
import AdminCareers from './components/adminPage/AddCareers/Careers';
import AdminJobDetail from  './components/adminPage/AddCareers/JobDetails';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />
    },

     {
      path: "/products/:categoryId",
      element: <Products />
    },
    {
      path: "/products/:categoryId/:subCategoryId/:productId/:categoryName/:subCategoryName",
      element: <ProductDetail />
    },
    {
      path: "/careers",
      element: <Careers />
    },
    {
      path: "/careers/jobdetails/*",
      element: <JobDetail />
    },
    {
      path: "/contact",
      element: <Contact />
    },

    {
      path: "/signin",
      element:  <SignIn />
    },
    {
      path: "/admin",
      element:  <AdminPage />
    },
    {
      path: "/addproduct",
      element:  <AddProducts /> 
    },
    {
      path: "/addclient",
      element:   <AddClients /> 
    },
    {
      path: "/addcategory",
      element: <AddCategories />  
    },
    {
      path: "/addtestimonial",
      element: <AddTestimonials /> 
    },
    {
      path: "/addmanual",
      element: <AddManuals /> 
    },
    {
      path: "/admincareer",
      element: <AdminCareers /> 
    },
    {
      path: "/admincareer/addcareer/*",
      element: <AddCareers /> 
    },
    {
      path: "/admincareer/jobdetails/*",
      element: <AdminJobDetail />
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ])
  return (
    <div className="w-full">

     
        {/* 
        <Route path="/" element={<Home />} />
        <Route path="/products/:id" element={<Products />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/knowledgecenter" element={<KnowledgeCenter />} />
        <Route path="/support" element={<Support />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/admin" element={<Protected>  <AdminPage /> </Protected>} />
        <Route path="/addproduct" element={<Protected>  <AddProduct /> </Protected>} />
        <Route path="/showproducts" element={<Protected>  <ViewProducts /> </Protected>} />
        */}
        <RouterProvider router={router} ></RouterProvider>
        <Scroll />
        <SocialMediaButtons />

    </div>
  );
}

export default App;
