import { useRef, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "@material-tailwind/react";
import { GET_CAREER_JOBS } from "../../Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faBan, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Careers = () => {

    const jobsSectionRef = useRef(null);

    const [jobs, setJobs] = useState([]);

      const navigate = useNavigate();

    useEffect(() => {
        // Fetch jobs from the backend
        const fetchJobs = async () => {
            try {
                const response = await axios.get(GET_CAREER_JOBS, {
                    params: {
                        userSide: 'admin',
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setJobs(response.data.jobs);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };

        fetchJobs();
    }, []);

    async function handleSignOut() {
        try {
    
          localStorage.removeItem("user");
          localStorage.removeItem("authToken"); // Remove the token from localStorage
          localStorage.setItem("isLoggedIn", false);
          navigate("/signin");
        } catch (error) {
          console.log(error);
        }
      }

    return (
        <div>
            <div className="Careers">
                {/* Header Section */}
                <div className="flex justify-between items-center mb-4 mt-8 ml-8 mr-8">
                    <Link
                        to="/admin"
                        className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300 shadow-md transform hover:scale-105"
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                        <span className="ml-2">Go Back</span>
                    </Link>

                            <Button
                              className="hidden md:block"
                              onClick={() => {
                                handleSignOut();
                              }}
                            >
                              Sign out
                            </Button>
                </div>
                <div
                    ref={jobsSectionRef}
                    className="flex flex-col items-center text-center px-5 md:px-10 lg:px-20 my-10"
                >
                    <h1 className="text-4xl md:text-5xl font-normal mt-10 leading-tight">
                        Manage Existing Job Listings
                    </h1>
                    <h1 className="text-4xl md:text-5xl font-normal mt-2 leading-tight">
                        Explore, Enable, Edit, or Delete Jobs with Ease
                    </h1>

                    {/* Add Job Button */}
                    <div className="mt-10">
                        <Link
                            to="/admincareer/addcareer"
                            className="bg-gradient-to-r from-green-400 to-green-600 text-white px-8 py-3 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:from-green-500 hover:to-green-700 hover:shadow-2xl"
                        >
                            + Create Job
                        </Link>
                    </div>

                </div>

                {/* Cards Section */}
                <div className="flex flex-wrap gap-6 justify-center mt-18 mb-10 px-5 md:px-10 lg:px-20">
                    {jobs.length > 0 ? (
                        jobs.map((job) => (
                            <Link
                                key={job.id}
                                to={`/admincareer/jobdetails?jobid=${job.id}`}
                                className="flex flex-col items-start text-left bg-white border border-gray-300 rounded-xl p-6 shadow-lg w-full sm:w-1/2 lg:w-1/4 cursor-pointer transition-transform transform hover:scale-105 hover:border-green-500 hover:shadow-2xl hover:ring-2 hover:ring-green-300 group"
                            >
                                {/* Job Title */}
                                <h3
                                    className="text-xl font-bold mb-3 text-gray-800 transition-all duration-300 group-hover:tracking-widest group-hover:text-green-600"
                                >
                                    {job.jobTitle}
                                </h3>

                                {/* Job Description (with line clamp for truncation) */}
                                <p className="text-sm text-gray-600 mb-4 line-clamp-3 group-hover:text-gray-700 transition-colors duration-300">
                                    {job.jobDescription}
                                </p>
                      
                                <FontAwesomeIcon 
                                    icon={job.jobStatus ? faCircleCheck : faBan} 
                                    fade 
                                    style={{ color: job.jobStatus ? "#03c200" : "#fe3434" }} 
                                />
                            </Link>
                        ))
                    ) : (
                        // Loading State
                        <p className="text-gray-500 text-center">Currently, there are no jobs available. Please check back later or create new opportunities.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Careers;
