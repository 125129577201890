import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SignOutBtn from "./SignOutBtn/SignOutBtn";
import { jwtDecode } from "jwt-decode";

const AdminPage = () => {
  //check if user is authenticated
  //if not, redirect to signin page
  //if yes, show admin page
  const navigate = useNavigate();
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const isTokenExpired = (token) => {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      const expiryTime = new Date(decodedToken.exp * 1000);
      console.log("Token expiry time:", expiryTime.toLocaleString());
      return decodedToken.exp < currentTime;
    };
    if (!authToken || isTokenExpired(authToken)) {
      console.log("AuthContext - user:", false);
      navigate("/signin");
    } else {
      console.log("AuthContext - user:", true);
    }
  }, []);

  return (
    // <div>AdminPage
    //   <div>
    //   <button onClick={() => {handleSignOut()}} >Sign out</button>
    //   </div>

    // </div>

    <div className="w-full">
      <SignOutBtn />
      <div>
        <div>
          <h2 className="mt-20 mb-20 text-center text-xl md:text-3xl font-extrabold text-gray-900">
            Select options to proceed
          </h2>
        </div>
        <div className="m-4 p-4 flex flex-col justify-center items-center md:flex-row md:justify-center align-middle md:items-center">
          <div className=" w-2/3 my-4 h-16 md:w-1/5 md:h-40 mx-5 flex flex-row justify-center align-middle items-center bg-gray-400 hover:bg-orange-100 rounded-xl">
            <Link to="/addproduct">
              <p className="font-medium"> Products</p>
            </Link>
          </div>
          <div className="w-2/3 my-4 h-16 md:w-1/5 md:h-40 mx-5 flex flex-row justify-center align-middle items-center bg-gray-400 hover:bg-orange-100 rounded-xl">
            <Link to="/addclient">
              <p className="font-medium"> Clients</p>
            </Link>
          </div>
          <div className="w-2/3 my-4 h-16 md:w-1/5 md:h-40 mx-5 flex flex-row justify-center align-middle items-center  bg-gray-400 hover:bg-orange-100  rounded-xl">
            <Link to="/addcategory">
              <p className="font-medium"> Categories</p>
            </Link>
          </div>
          <div className="w-2/3 my-4 h-16 md:w-1/5 md:h-40 mx-5 flex flex-row justify-center align-middle items-center  bg-gray-400 hover:bg-orange-100  rounded-xl">
            <Link to="/addtestimonial">
              <p className="font-medium"> Testimonials</p>
            </Link>
          </div>
          <div className="w-2/3 my-4 h-16 md:w-1/5 md:h-40 mx-5 flex flex-row justify-center align-middle items-center  bg-gray-400 hover:bg-orange-100  rounded-xl">
            <Link to="/addmanual">
              <p className="font-medium"> Product Manual</p>
            </Link>
          </div>
          <div className="w-2/3 my-4 h-16 md:w-1/5 md:h-40 mx-5 flex flex-row justify-center align-middle items-center  bg-gray-400 hover:bg-orange-100  rounded-xl">
            <Link to="/admincareer">
              <p className="font-medium"> Careers</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
